import { render, staticRenderFns } from "./ReaderFile.vue?vue&type=template&id=3e47fa1c&scoped=true"
import script from "./ReaderFile.vue?vue&type=script&lang=js"
export * from "./ReaderFile.vue?vue&type=script&lang=js"
import style0 from "./ReaderFile.vue?vue&type=style&index=0&id=3e47fa1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e47fa1c",
  null
  
)

export default component.exports