<template>
  <div @click="handleClick">
    <input ref="input"
           type="file"
           :accept="accept"
           :multiple="multiple"
           :webkitdirectory="webkitdirectory"
           @change="handleChange"
           class="input-style">
    <slot></slot>
  </div>
</template>

<script>
import axios from '@/api/http.interceptor'
export default {
  props: {
    accept: {
      type: String,
      default: 'image/*'
    },
    multiple: Boolean,
    webkitdirectory: Boolean,
    disabled: null,
    uploadpath: String,
    action: {
      type: String,
      required: true
    },
    type: String,
    workType: [String, Number]
  },
  data () {
    return {
      resultArr: []
    }
  },
  methods: {
    handleClick () {
      if (this.disabled) return
      this.$refs.input.click()
    },
    handleChange (e) {
      const files = e.target.files
      if (!files) return
      this.upload(files)
      this.$refs.input.value = null
    },
    upload (files) {
      let filelist = Array.from(files)
      this.$emit('uploadstart')
      this.resultArr = []
      filelist.forEach(item => {
        let reader = new FileReader()
        reader.readAsDataURL(item)
        reader.onload = () => {
          let fileName = item.name
          let file = new FormData();
          file.append('files', item)
          if (this.type) {
            file.append('types', this.type)
          }
          if (this.workType) {
            file.append('type', this.workType)
          }
          this.uploadFile(file, fileName, filelist)
        }
      })
    },
    // 导入文件
    uploadFile (file, fileName, filelist) {
      axios.post(this.action, file, {
        headers: {
          'Content-Type': 'multipart/form-data;'
        }
      }).then((res) => {
        if (res.code === 200) {
          let result = {
            url: res.result,
            fileName
          }
          this.resultArr.push(result)
          if (this.resultArr.length === filelist.length) {
            // this.$emit('uploadSuccess', this.resultArr)
			let cont = `
			  <div>
			      ${res.result}
			  </div>
			`
			this.$Modal.success({
			  title: '导入成功',
			  content: cont,
			});
          }
        } else {
          let arr = res.message.split('##')
          let cont = `
            <div>
                ${arr.map((item) => {
            return `<div>${item}</div>`
          }).join('')}
            </div>
          `
          this.$Modal.error({
            title: '导入失败',
            content: cont,
          });
          // this.$Message.info(res.message)
        }
      }).catch((err) => {
        this.$Message.info('导入失败')
      })
    },
    readerFile (params) {
    }
  }
}
</script>

<style lang="scss" scoped>
.input-style {
  display: none;
}
</style>
